<template>
  <div>
    <router-link class="come-back" to="/admin/connect_contacts">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">Редактирование контакта</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createContact" ref="createContact">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Вид связи<span>*</span></p>
          <input required v-model="contactsBlocks[0].title" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Впишите номера телефонов через запятую<span>*</span></p>
          <div class="block_add">
              <input v-model="contactsBlocks[0].data[0]" type="text" name="workDay" class="input blue">
              <div  v-for="(item, i) in contactsBlocks[0].data" :key="'phone'+i">
                <input v-if="i!==0" v-model="contactsBlocks[0].data[i]" type="text" name="workDay" class="input blue">
                <img src="/assets/icons/close.svg" @click="deletePhone(i, 0)" v-if="i!==0">
              </div>
            <div class="btn-blue" @click="addPhone(0)">
                Добавить телефон
            </div>
          </div>
        </div>

        <div v-for="(contactBlock, index) in contactsBlocks" :key="`contactBlock${index}`" class="contacts__block">
            <header v-if="index!==0">
                <h6> Блок графика работы №{{ index+1 }}</h6>
                <img src="/assets/icons/close.svg" @click="deleteContactBlock(index)">
            </header>
            <div class="content-sb" v-if="index!==0">
                <p>Вид связи<span>*</span></p>
                <input required v-model="contactsBlocks[index].title" type="text" name="title" class="input blue">
            </div>
            <div class="content-sb"  v-if="index!==0">
                <p>Впишите номера телефонов через запятую<span>*</span></p>
                <div class="block_add">
                    <input v-model="contactsBlocks[index].data[0]" type="text" name="workDay" class="input blue">
                    <div  v-for="(item, i) in contactsBlocks[index].data" :key="'phone'+i">
                        <input v-if="i!==0" v-model="contactsBlocks[index].data[i]" type="text" name="workDay" class="input blue">
                        <img src="/assets/icons/close.svg" @click="deletePhone(i, index)" v-if="i!==0">
                    </div>
                    <div class="btn-blue" @click="addPhone(index)">
                        Добавить телефон
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-blue" @click="addBlock()">
          Добавить блок
        </div>
      </div>

      <div class="inputs__container">
        <div class="content-sb">
          <p>Вид связи (Англ)<span>*</span></p>
          <input required v-model="contactsBlocksEng[0].title" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Впишите номера телефонов через запятую (Англ)<span>*</span></p>
          <div class="block_add">
            <input v-model="contactsBlocksEng[0].data[0]" type="text" name="workDay" class="input blue">
            <div  v-for="(item, i) in contactsBlocksEng[0].data" :key="'phone'+i">
              <input v-if="i!==0" v-model="contactsBlocksEng[0].data[i]" type="text" name="workDay" class="input blue">
              <img src="/assets/icons/close.svg" @click="deletePhoneEng(i, 0)" v-if="i!==0">
            </div>
            <div class="btn-blue" @click="addPhoneEng(0)">
              Добавить телефон (Англ)
            </div>
          </div>
        </div>

        <div v-for="(contactBlock, index) in contactsBlocksEng" :key="`contactBlock${index}`" class="contacts__block">
          <header v-if="index!==0">
            <h6> Блок графика работы №{{ index+1 }}</h6>
            <img src="/assets/icons/close.svg" @click="deleteContactBlockEng(index)">
          </header>
          <div class="content-sb" v-if="index!==0">
            <p>Вид связи<span>*</span></p>
            <input required v-model="contactsBlocksEng[index].title" type="text" name="title" class="input blue">
          </div>
          <div class="content-sb"  v-if="index!==0">
            <p>Впишите номера телефонов через запятую<span>*</span></p>
            <div class="block_add">
              <input v-model="contactsBlocksEng[index].data[0]" type="text" name="workDay" class="input blue">
              <div  v-for="(item, i) in contactsBlocksEng[index].data" :key="'phone'+i">
                <input v-if="i!==0" v-model="contactsBlocksEng[index].data[i]" type="text" name="workDay" class="input blue">
                <img src="/assets/icons/close.svg" @click="deletePhoneEng(i, index)" v-if="i!==0">
              </div>
              <div class="btn-blue" @click="addPhoneEng(index)">
                Добавить телефон (Англ)
              </div>
            </div>
          </div>
        </div>
        <div class="btn-blue" @click="addBlock()">
          Добавить блок
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/connect_contacts" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'CreateInformationService',

  data () {
    return {
      contactsBlocks: [
        {
          title: '',
          data: ['']
        }
      ],
      contactsBlocksEng: [
        {
          title: '',
          data: ['']
        }
      ]
    }
  },
  watch: {
    '$store.getters.getInformationService' () {
      const dataFromServer = this.$store.getters.getInformationService
      this.contactsBlocks = dataFromServer.blocs.map((block) => { return { ...block } })
      this.contactsBlocksEng = dataFromServer.blocs_eng.map((block) => { return { ...block } })
    }
  },
  created () {
    this.$store.dispatch('setInformationService')
  },

  methods: {
    dataTags (value, index) {
      this.contactsBlocks[index].data = value
      console.log(value, index)
    },
    createContact () {
      const formData = new FormData()
      formData.append('blocs', JSON.stringify(this.contactsBlocks))
      formData.append('blocs_eng', JSON.stringify(this.contactsBlocksEng))
      this.$store.dispatch('editInformationService', formData)
    },
    addBlock () {
      this.contactsBlocks.push({
        title: '',
        data: []
      })
    },
    deleteContactBlock (index) {
      this.contactsBlocks.splice(index, 1)
    },
    addPhone (index) {
      this.contactsBlocks[index].data.push('')
    },
    deletePhone (index, i) {
      this.contactsBlocks[i].data.splice(index, 1)
    },
    dataTagsEng (value, index) {
      this.contactsBlocksEng[index].data = value
      console.log(value, index)
    },
    addBlockEng () {
      this.contactsBlocksEng.push({
        title: '',
        data: []
      })
    },
    deleteContactBlockEng (index) {
      this.contactsBlocksEng.splice(index, 1)
    },
    addPhoneEng (index) {
      this.contactsBlocksEng[index].data.push('')
    },
    deletePhoneEng (index, i) {
      this.contactsBlocksEng[i].data.splice(index, 1)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
  .block_add{
    width: 33.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div{
        display: flex;
    }
    input{
        width: 31.75rem;
        margin: 0 1rem 1rem 0;
    }
    img{
        cursor: pointer;
    }
    .btn-blue{
      width: max-content;
    }
  }

</style>
